import { AxiosError } from "axios";
import { httpRefreshCDV } from "@/services/axios.service";

export interface PostRefreshParams {
  account: string;
}
export const Refresh = async (
  PostRefreshCDV: PostRefreshParams
): Promise<void> => {
  try {
    const url = `${PostRefreshCDV.account}/lifecycle/refresh`;
    const resData = await httpRefreshCDV.post<void>(url);
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
