import { ShowNotification } from "@/helpers/notification.helper";
import { DeletedContacts, recoverContacts } from "../api/contactRecovery.api";

type RecoverContactsParams = { account: string; date: string };

export const RecoverContacts = async ({
  account,
  date,
}: RecoverContactsParams): Promise<DeletedContacts[]> => {
  try {
    const deletedContacts = await recoverContacts({ account, date });

    return deletedContacts;
  } catch (error) {
    ShowNotification(
      "Error",
      "Ocurrió un error al intentar recuperar los contactos.",
      "error"
    );
    return Promise.reject({});
  }
};
