import { ShowNotification } from "@/helpers/notification.helper";
import {
  DeletedList,
  getDeletedLists,
  recoverDeletedList,
} from "../api/listRecovery.api";

type SearchDeletedListsParams = { account: string };
type RecoverDeletedListParams = { account: string; listId: number };

export const SearchDeletedLists = async ({
  account,
}: SearchDeletedListsParams): Promise<DeletedList[]> => {
  try {
    const response = await getDeletedLists({ account });
    return response;
  } catch (error) {
    ShowNotification(
      "Error",
      "Ocurrió un error al recuperar las listas eliminadas.",
      "error"
    );
    return Promise.reject({});
  }
};

export const RecoverDeletedList = async ({
  account,
  listId,
}: RecoverDeletedListParams): Promise<void> => {
  try {
    await recoverDeletedList({ account, listId });
  } catch (error) {
    ShowNotification(
      "Error",
      `Ocurrió un error al intentar recuperar la lista #${listId}.`,
      "error"
    );
    return Promise.reject({});
  }
};
