import { httpClient, httpPEM } from "../axios.service";
import { SuccessAPIOffsetPaging } from "../models/generic.model";

export type DeletedList = {
  id: number;
  name: string;
  deleted: string;
};

export const getDeletedLists = async ({
  account,
}: {
  account: string;
}): Promise<DeletedList[]> => {
  try {
    const resData = await httpPEM.get<
      SuccessAPIOffsetPaging<DeletedList[]>
    >(`/${account}/lists/recovery`, {
      headers: {
        "X-Auth-Token": httpClient.defaults.headers.common["X-Auth-Token"],
        "X-Account": account,
      },
    });

    return resData.data.data;
  } catch (error) {
    return Promise.reject("Error");
  }
};

export const recoverDeletedList = async ({
  account,
  listId,
}: {
  account: string;
  listId: number;
}): Promise<void> => {
  try {
    await httpPEM.post<SuccessAPIOffsetPaging<DeletedList[]>>(
      `/${account}/lists/recovery`,
      { listId },
      {
        headers: {
          "X-Auth-Token": httpClient.defaults.headers.common["X-Auth-Token"],
          "X-Account": account,
        },
      }
    );
  } catch (error) {
    return Promise.reject("Error");
  }
};
