import { httpClient, httpPEM } from "../axios.service";
import { SuccessAPIOffsetPaging } from "../models/generic.model";

export type DeletedContacts = {
  email: string;
  contact_id: number;
  deleted: string;
};

export const recoverContacts = async ({
  account,
  date,
}: {
  account: string;
  date: string;
}): Promise<DeletedContacts[]> => {
  try {
    const resData = await httpPEM.post<
      SuccessAPIOffsetPaging<DeletedContacts[]>
    >(
      `/${account}/contacts/recovery`,
      { date },
      {
        headers: {
          "X-Auth-Token": httpClient.defaults.headers.common["X-Auth-Token"],
          "X-Account": account,
        },
      }
    );

    return resData.data.data;
  } catch (error) {
    return Promise.reject("Error");
  }
};
